import { SVGProps } from 'react';

function WhatsappOutline({
  fill = 'var(--neutral-0)',
  width = 16,
  height = 16,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="var(--neutral-0)"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3358 2.69419C12.6428 1.99659 11.8182 1.44345 10.9099 1.06681C10.0015 0.690175 9.02749 0.497525 8.04417 0.500024C3.92083 0.500024 0.564167 3.85586 0.5625 7.98086C0.5625 9.29919 0.906666 10.5859 1.56083 11.7209L0.5 15.5967L4.46583 14.5559C5.56283 15.153 6.79183 15.4661 8.04083 15.4667H8.04417C12.1667 15.4667 15.5233 12.1109 15.525 7.98586C15.5278 7.00293 15.3358 6.02918 14.9601 5.12091C14.5843 4.21263 14.0323 3.38783 13.3358 2.69419ZM8.04417 14.2042H8.04167C6.92793 14.2043 5.83469 13.9047 4.87667 13.3367L4.65 13.2017L2.29667 13.8192L2.92417 11.525L2.77667 11.29C2.15399 10.2987 1.82436 9.15151 1.82583 7.98086C1.8275 4.55252 4.61667 1.76336 8.04667 1.76336C8.86354 1.76154 9.67267 1.92176 10.4272 2.23473C11.1818 2.54771 11.8667 3.00723 12.4425 3.58669C13.0214 4.16339 13.4802 4.8491 13.7924 5.60419C14.1047 6.35928 14.2641 7.16876 14.2617 7.98586C14.26 11.4142 11.4708 14.2042 8.04417 14.2042ZM11.455 9.54669C11.2675 9.45252 10.3492 9.00086 10.1775 8.93836C10.0067 8.87586 9.8825 8.84502 9.7575 9.03169C9.6325 9.21836 9.27417 9.63919 9.165 9.76419C9.05583 9.88919 8.94667 9.90419 8.76 9.81086C8.57333 9.71752 7.97083 9.52002 7.25667 8.88336C6.70083 8.38752 6.32583 7.77586 6.21667 7.58836C6.1075 7.40086 6.205 7.30002 6.29917 7.20669C6.38333 7.12336 6.48583 6.98836 6.57917 6.87919C6.6725 6.77002 6.70333 6.69252 6.76583 6.56752C6.82833 6.44252 6.7975 6.33336 6.75 6.24002C6.70333 6.14586 6.32917 5.22586 6.17333 4.85169C6.0225 4.48919 5.86833 4.53752 5.75333 4.53252C5.63423 4.52765 5.51504 4.52543 5.39583 4.52586C5.30105 4.52827 5.20778 4.55025 5.12188 4.5904C5.03599 4.63055 4.95931 4.68801 4.89667 4.75919C4.725 4.94669 4.2425 5.39836 4.2425 6.31836C4.2425 7.23836 4.9125 8.12752 5.00583 8.25252C5.09917 8.37752 6.32417 10.265 8.19917 11.075C8.64583 11.2675 8.99417 11.3825 9.265 11.4692C9.7125 11.6117 10.12 11.5909 10.4425 11.5434C10.8017 11.49 11.5483 11.0917 11.7042 10.655C11.86 10.2184 11.86 9.84419 11.8133 9.76586C11.7667 9.68752 11.6408 9.64002 11.455 9.54669Z"
        fill={fill}
      />
    </svg>
  );
}

export default WhatsappOutline;
